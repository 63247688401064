import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@internal/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@internal/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@internal/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { HomePage } from '@internal/muziris-home-plugin';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
import {
  IdentityApi,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { setTokenCookie } from './modules/identity/Auth';
import { providers } from './modules/identity/identityProviders';
import { appThemes } from './theme/appThemes';
import { CarbonopsPage } from '@digitalinnovation/muziris-carbonops-plugin';
import { Vault } from '@digitalinnovation/muziris-vault-plugin';
import {
  WorklistPage,
  WorklistPageDetails,
} from '@digitalinnovation/muziris-worklist-plugin';
import { ExplorePage } from '@backstage/plugin-explore';
import { CostInsightsPage } from '@digitalinnovation/muziris-finops-plugin';
import { defaultPreviewTemplate } from './modules/scaffolder/defaultPreviewTemplate';
import { ValidateAzureAADGrpEmailFieldExtension } from './modules/scaffolder/API-Validations/Azure/AAD-Grp-email-validation';
import { ApplicationIDFieldExtension } from './modules/scaffolder/ApplicationIDExtension';
import { AzureADgroupFieldValidateExtension } from './modules/scaffolder/AzureADgroupValidate';
import { AzureActiveDirectoryFieldExtension } from './modules/scaffolder/AzureActiveDirectory';
import { AzureCustomFieldExtension } from './modules/scaffolder/AzureCustomExtension';
import { AzureNetworkFieldExtension } from './modules/scaffolder/AzureNetworkExtension';
import { AzureSubscriptionFieldExtension } from './modules/scaffolder/AzureSubscriptionExtension';
import { ComponentHopperFieldExtension } from './modules/scaffolder/ComponentHopper';
import { ConfluentCloudServiceAccountAccountLimiterFieldExtension } from './modules/scaffolder/ConfluentCloud/ServiceAccountCreationLimiter';
import { ConfluentCloudServiceAccountsListFieldExtension } from './modules/scaffolder/ConfluentCloud/ServiceAccounts';
import { UpsertTopicConfigFieldExtension } from './modules/scaffolder/ConfluentCloud/UpsertTopicConfig';
import { CreateEcosystemBuilderCNForm } from './modules/scaffolder/EcosystemBuilderCNCreation';
import { UpdateEcosystemBuilderExtension } from './modules/scaffolder/EcosystemBuilderUpdate';
import { UpdateEcosystemBuilderExtensionV2 } from './modules/scaffolder/EcosystemBuilderUpdateV2';
import { CreateEcosystemBuilderV2Form } from './modules/scaffolder/EcosystemBuilderV2Creation';
import { IocodeValidationExtensions } from './modules/scaffolder/IoCodeValidationExtension';
import { ProductStructureListExtension } from './modules/scaffolder/ProductStructureExtension';
import { AzureResourceGroupFieldValidateExtension } from './modules/scaffolder/ResourceGroupValidationExtension';
import { SignedInUserExtension } from './modules/scaffolder/SignedInUserEmailFieldExtension';
import { TimeStampFieldExtension } from './modules/scaffolder/TimeStampFieldExtension';
import RenderSupportButtonDrawer from './components/SupportButton/renderSupportButton';
import { CustomCatalogPage } from './components/catalog/CustomCatalogIndex';
import { BannerForUserRestrictionForCoPilot } from './modules/scaffolder/BannerForCopilotUserRestriction';
import { GitHubRepositoryExtension } from './modules/scaffolder/GitHubRepositoryFieldExtension';
import { ServiceStatusPage } from '@internal/backstage-plugin-service-status';
import { shortcutsPlugin } from '@backstage/plugin-shortcuts';
import { Link } from '@material-ui/core';

const app = createApp({
  apis,
  themes: appThemes,
  plugins: [shortcutsPlugin],
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          providers={[...providers]}
          title="Select a sign-in method"
          align="center"
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/home" element={<HomePage />} />
    {/* <Route path="/catalog" element={<CatalogIndexPage />} /> */}
    <Route path="/catalog" element={< CatalogIndexPage />} >
      <CustomCatalogPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          defaultPreviewTemplate={defaultPreviewTemplate}
          groups={[
            {
              title: '📌 Favourites',
              filter: entity =>
                entity?.metadata?.tags?.includes('favourite-entity') ?? false,
            },
            {
              title: 'GitHub Tools',
              filter: entity =>
                entity?.metadata?.tags?.includes('github') ?? false,
            },
            {
              title: 'Day 0 - Prep',
              filter: entity =>
                entity?.metadata?.tags?.includes('prep') ?? false,
            },
            {
              title: 'Day 1 - Create',
              filter: entity =>
                entity?.metadata?.tags?.includes('create') ?? false,
            },
            {
              title: 'Day 2 - Manage',
              filter: entity =>
                entity?.metadata?.tags?.includes('manage') ?? false,
            },
            {
              title: 'Day N - Sunset',
              filter: entity =>
                entity?.metadata?.tags?.includes('sunset') ?? false,
            },
            {
              title: 'Community',
              filter: entity =>
                entity?.metadata?.tags?.includes('community') ?? false,
            },
            // {
            //   title: 'Access & Onboarding',
            //   filter: entity =>
            //     entity?.metadata?.tags?.includes('access-onboarding') ?? false,
            // },
            // {
            //   title: 'Cloud9 Infrastructure',
            //   filter: entity =>
            //     entity?.metadata?.tags?.includes('cloud9-infrastructure') ??
            //     false,
            // },
            {
              title: 'Cloud9 Frameworks',
              filter: entity =>
                entity?.metadata?.tags?.includes('cloud9-frameworks') ?? false,
            },
            {
              title: 'Networks',
              filter: entity =>
                entity?.metadata?.tags?.includes('network') ?? false,
            },
            {
              title: 'Confluent Kafka',
              filter: entity =>
                entity?.metadata?.tags?.includes('confluentcloud') ?? false,
            },
            {
              title: 'Integration',
              filter: entity =>
                entity?.metadata?.tags?.includes('integration') ?? false,
            },
            {
              title: 'Community Templates',
              filter: entity =>
                entity?.metadata?.tags?.includes('community-templates') ??
                false,
            },
            {
              title: 'Delete Actions',
              filter: entity =>
                entity?.metadata?.tags?.includes('delete-action') ??
                false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <TimeStampFieldExtension />
        <IocodeValidationExtensions />
        <ApplicationIDFieldExtension />
        <ProductStructureListExtension />
        <ComponentHopperFieldExtension />
        <UpsertTopicConfigFieldExtension />
        <ConfluentCloudServiceAccountsListFieldExtension />
        <ConfluentCloudServiceAccountAccountLimiterFieldExtension />
        <AzureSubscriptionFieldExtension />
        <AzureActiveDirectoryFieldExtension />
        <AzureNetworkFieldExtension />
        <SignedInUserExtension />
        <AzureADgroupFieldValidateExtension />
        <AzureResourceGroupFieldValidateExtension />
        <ValidateAzureAADGrpEmailFieldExtension />
        <UpdateEcosystemBuilderExtension />
        <UpdateEcosystemBuilderExtensionV2 />
        <CreateEcosystemBuilderV2Form />
        <CreateEcosystemBuilderCNForm />
        <BannerForUserRestrictionForCoPilot />
        <AzureCustomFieldExtension />
        <GitHubRepositoryExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/carbonops" element={<CarbonopsPage />} />
    <Route path="/vault" element={<Vault />} />
    <Route path="/worklist" element={<WorklistPage />} />
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/finops" element={<CostInsightsPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />;
    <Route path="/worklist/:taskId" element={<WorklistPageDetails />} />
    {/* <Route path="/catalog" element={<CatalogIndexPage1 />} /> */}
    <Route path="/service-status" element={<ServiceStatusPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <RenderSupportButtonDrawer />
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <div style={{ textAlign: "center", padding: "20px", backgroundColor: "#000000", color: "white", }}>
        <div style={{ fontSize: "1rem", textAlign: "center", marginLeft: "10%", display: "flex", alignContent: "center", justifyContent: "center" }}>
          Exciting News! 🚀 We're moving to a new Akamai-based URL starting &nbsp;<Link style={{ color: "#F49F0A" }}>February 20</Link>
        </div>
        <div style={{ fontSize: "1rem", textAlign: "center", marginLeft: "10%", display: "flex", alignContent: "center", justifyContent: "center" }}>
          Explore the new URL in advance: &nbsp;<Link style={{ color: "#F49F0A" }}><a target="_blank" href="https://portal.muziris.cloud.marksandspencer.app">https://portal.muziris.cloud.marksandspencer.app</a> </Link>    &nbsp;&nbsp; Get ready for a smoother, faster experience! 🎉
        </div>
        <div style={{ fontSize: "1rem", textAlign: "center", marginLeft: "10%", display: "flex", alignContent: "center", justifyContent: "center" }}><a target="_blank" href="https://github.com/DigitalInnovation/technology-muziris/discussions/categories/support-help">If you find any issue in new version, please feel free to <Link style={{ color: "#F49F0A" }}>create an issue</Link> </a></div>
      </div>
      <Root>{routes}</Root>
    </AppRouter >
  </>,
);
